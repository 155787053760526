.support-link-component {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px 28px;
    color: white;
    background-color: #94B4A4;
    border-radius: 48px;
    text-decoration: none;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
}
.support-link-component:hover {
    box-shadow: 0 6px 10px rgba(162, 198, 195, 0.35);
}
.support-link-component svg {
    margin-right: 8px;
}
.support-link-component path {
    stroke: white;
}