.prefer-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #181827;
    margin-bottom: 16px;
}
.radio-button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 400px;
    background: transparent;
    padding: 0;
    margin-bottom: 12px;
}
.radio-button:last-of-type {
    margin-bottom: 0;
}
.radio-button-img-wrapper {
    width: 32px;
    height: 32px;
    background-color: #C9C9C9;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
}
.radio-button--active .radio-button-img-wrapper,
.radio-button:hover .radio-button-img-wrapper {
    background-color: #94B4A4;
}
.radio-button-img-wrapper svg {
    width: 16px;
    height: 16px;
}
.mail path:first-of-type {
    fill: white;
    stroke: white;
}
.mail path:last-of-type {
    stroke: #C9C9C9;
}
.radio-button-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #181827;
}
.radio-button--active .radio-button-checkmark:after {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    background-color: #94B4A4;
    border-radius: 50%;
}
.radio-button-checkmark {
    position: relative;
    margin-left: auto;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid #C9C9C9;
}


.intl-tel-input.allow-dropdown .selected-flag {
    width: 74px;
    border-right: 1px solid #F0F0F0;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 7.5L10 12.5L15 7.5' stroke='%2394B4A4' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center right 5px;
}
.intl-tel-input.allow-dropdown.expanded .selected-flag {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 12.5L10 7.5L15 12.5' stroke='%2394B4A4' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}
.intl-tel-input.allow-dropdown .flag-container:hover .selected-flag {
    background-color: transparent;
}
.intl-tel-input.allow-dropdown input.tab-input {
    padding-left: 93px;
}
#root .intl-tel-input .flag-container .arrow:after {
    content: none;
}
.intl-tel-input .country-list {
    width: 400px;
    overflow-x: hidden;
    margin-top: 12px;
    padding: 16px;
    background-color: #FFFFFF;
    border: 1px solid #F0F0F0;
    border-radius: 12px;
}
.intl-tel-input .country-list .country.highlight {
    background-color: transparent;
}
.intl-tel-input .country-list .country-name {
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: #181827;
}
.intl-tel-input .country-list .country.highlight .country-name {
    color: #94B4A4;
}