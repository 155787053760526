.new-password-hint {
    display: inline-block;
    background-color: #F0F0F0;
    border-radius: 24px;
    padding: 6px 8px;
    margin: 4px 4px 0 0;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    color: #999999;
    position: relative;
}
.new-password-hint--active {
    padding-left: 28px;
    color: #94B4A4;
}
.new-password-hint--active:before {
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.33398 7.99984L6.66732 11.3332L13.334 4.6665' stroke='%2394B4A4' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 6px;
    left: 8px;
}
.password svg {

}
.pass-type-toggle {
    cursor: pointer;
    position: absolute;
    top: 14px;
    right: 14px;
    width: 20px;
    height: 20px;
}
input[type="password"] ~ .pass-type-toggle {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.0007 11.6667C10.9211 11.6667 11.6673 10.9205 11.6673 10C11.6673 9.07957 10.9211 8.33337 10.0007 8.33337C9.08018 8.33337 8.33398 9.07957 8.33398 10C8.33398 10.9205 9.08018 11.6667 10.0007 11.6667Z' stroke='%23999999' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M18.3327 9.99996C16.1102 13.8891 13.3327 15.8333 9.99935 15.8333C6.66602 15.8333 3.88852 13.8891 1.66602 9.99996C3.88852 6.11079 6.66602 4.16663 9.99935 4.16663C13.3327 4.16663 16.1102 6.11079 18.3327 9.99996Z' stroke='%23999999' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}
input[type="text"] ~ .pass-type-toggle {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.5 2.5L17.5 17.5' stroke='%23999999' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8.8207 8.82239C8.50797 9.1349 8.33219 9.55885 8.33203 10.001C8.33188 10.4431 8.50736 10.8672 8.81987 11.1799C9.13238 11.4926 9.55633 11.6684 9.99845 11.6686C10.4406 11.6687 10.8646 11.4932 11.1774 11.1807' stroke='%23999999' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.80185 4.47084C8.51636 4.26644 9.25618 4.16404 9.99935 4.16668C13.3327 4.16668 16.1102 6.11084 18.3327 10C17.6843 11.1342 16.9893 12.1033 16.2468 12.9067M14.4635 14.4575C13.1043 15.3742 11.6177 15.8333 9.99935 15.8333C6.66602 15.8333 3.88852 13.8892 1.66602 10C2.80685 8.00418 4.09352 6.52084 5.52602 5.54918' stroke='%23999999' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

.caps-lock {
    position: absolute;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    color: #C25E6B;
    top: 50%;
    transform: translate(100%, -50%);
    right: -12px;
}
.field-wrapper--error .caps-lock {
    right: -38px;
}