.patient-chart-section-wrapper {
    margin-top: 40px;
}
.patient-chart-title {
    font-weight: 800;
    font-size: 26px;
    line-height: 36px;
    color: #000000;
    margin-bottom: 24px;
}
.patient-chart-content-wrapper {
    position: relative;
    background-color: #FFFFFF;
    border: 1px solid #F0F0F0;
    border-radius: 24px;
    padding: 24px;
    overflow: auto;
}

.patient-chart-controls-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 57px;
}
.patient-chart-step-control-wrapper {
    display: flex;
    align-items: baseline;
}
.patient-chart-step-control {
    display: flex;
    align-self: center;
    background-color: transparent;
}
.patient-chart-step-control--prev {
    padding-left: 0;
    margin-left: -7px;
}
.patient-chart-step-control-text {
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    color: #9A975A;
    /*margin-left: 32px;*/
}
/*.patient-chart-step-control ~ .patient-chart-step-control-text {
    margin-left: 0;
}*/
.patient-chart-step-control-date-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #999999;
}

.patient-chart-type-controls-wrapper {
    display: flex;
}
.patient-chart-type-control {
    position: relative;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    margin-left: 15px;
    text-align: center;
    color: #999999;
    background-color: transparent;
}
.patient-chart-type-control:hover {
    color: #9A975A;
}
.patient-chart-type-control--active {
    font-weight: 800;
    color: #9A975A;
}
.patient-chart-type-control--active:before {
    content: '';
    position: absolute;
    display: block;
    width: 24px;
    height: 4px;
    top: -24px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #9A975A;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.patient-chart-notice {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 12px 0 30px 45px;
}
.patient-chart-axes-title {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #999999;
}
.y-axes {
    position: absolute;
    top: 70px;
}
.legend-labels-wrapper {
    display: flex;
    justify-content: flex-end;
}
.legend-label {
    position: relative;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #94B4A4;
    margin-right: 32px;
    padding-left: 16px;
}
.legend-label--low {
    color: #214C4F;
}
.legend-label--high {
    color: #C25E6B;
}
.legend-label:last-of-type {
    margin-right: 0;
}
.legend-label:before {
    content: '';
    position: absolute;
    display: block;
    width: 8px;
    height: 8px;
    left: 0;
    bottom: 4px;
    border-radius: 50%;
    background-color: #94B4A4;
}
.legend-label--low:before {
    background-color: #214C4F;
}
.legend-label--high:before {
    background-color: #C25E6B;
}


.patient-chart-doughnut-wrapper {
    display: flex;
    align-items: center;
    background-color: #FFFFFF;
    border: 1px solid #F0F0F0;
    border-radius: 16px;
    padding: 16px;
    width: 289px;
}
.patient-canvas-doughnut-wrapper {
    position: relative;
    margin: -4px 0 -4px -4px;
}
.patient-canvas-doughnut-wrapper svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.patient-canvas-doughnut-wrapper--high path {
    fill: #C25E6B;
}
.patient-canvas-doughnut-wrapper--low path {
    fill: #214C4F;
}
.patient-chart-doughnut-details {
    margin-left: 26px;
}
.patient-chart-doughnut-value {
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    color: #94B4A4;
    margin-bottom: 2px;
}
.patient-chart-doughnut-value--high {
    color: #C25E6B;
}
.patient-chart-doughnut-value--low {
    color: #214C4F;
}
.patient-chart-doughnut-text {
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #94A8AB;
    min-height: 44px;
}