@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700&display=swap);
.main-wrapper {
    display: flex;
    background-color: #181827;
    min-height: 100vh;
}
@media (max-width: 1300px) {
    .main-wrapper {
        flex-direction: column;
    }
}
/*.main-wrapper--menu-opened {
    height: 100vh;
}*/

.main-layout {
    position: relative;
    background-color: #F7FBF9;
    width: 100%;
    border-top-left-radius: 24px;
    margin-left: -32px;
    /*min-height: 100vh;*/
    /*height: auto;*/
    /*overflow: auto;*/
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
}
@media (max-width: 1300px) {
    .main-layout {
        height: auto;
        margin-left: 0;
        min-height: 100vh;
        border-radius: 0;
    }
}

.page-header {
    display: flex;
    justify-content: space-between;
    padding: 48px 40px;
    background-color: #FFFFFF;
    box-shadow: 0 6px 24px rgba(233, 235, 233, 0.35);
    border-top-left-radius: 24px;
}
@media (max-width: 1300px) {
    .page-header {
        padding: 24px;
        border-bottom-left-radius: 24px;
    }
}
.page-content-wrapper {
    padding: 48px 40px;
}
.page-content-inner-wrapper {
    max-width: 1102px;
    margin: 0 auto;
}

.wave-effect {
    position: absolute;
    border-radius: 50%;
    transform: scale(0);
    animation: ripple 600ms linear;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 111;
}
@keyframes ripple {
    to {
        transform: scale(4);
        opacity: 0;
    }
}

.tab-button.update_email_send {
    padding: 13px 6px;
}
@keyframes move-left {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}
@keyframes move-right {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
}
@keyframes move-top {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}
@keyframes move-bottom {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(100%);
    }
}
.highlight {
    color: #9A975A;
}
body .iti-mobile .intl-tel-input .country-list {
    max-height: 200px;
}
.initial-screen-wrapper {
    display: flex;
    background-color: #F7FBF9;
    height: 100%;
    min-height: 100vh;
    min-width: 1440px;
}

.initial-screen-data-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35%;
    min-width: 600px;
    padding: 56px 120px;
    background-color: white;
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
}
.initial-screen-data-wrapper--flex-start {
    align-items: flex-start;
}
.initial-screen-data-wrapper label {
    display: block;
    text-align: left;
    margin-bottom: 24px;
}
.initial-screen-data-wrapper input {
    width:  360px;
    padding: 13px 18px;
}
.initial-screen-data-wrapper .is-button {
    position: relative;
    width:  360px;
    background-color: #94B4A4;
    color: white;
    border-radius: 12px;
    text-align: center;
    padding: 13px 18px;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    overflow: hidden;
}
.initial-screen-data-wrapper .is-button.disabled {
    background-color: #F0F0F0;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    pointer-events: none;
}
.initial-screen-data-container {
    max-width: 360px;
    width: 100%;
}
.initial-screen-logo {
    display: block;
    margin: 0 auto;
}
.initial-screen-title {
    font-weight: 800;
    font-size: 26px;
    line-height: 36px;
    text-align: center;
    color: #181827;
    margin-top: 40px;
    margin-bottom: 32px;
}
button.continue-google {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 360px;
    height: 48px;
    background-color: #214C4F;
    border-radius: 12px;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
}
button.continue-apple {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 360px;
    height: 48px;
    border: 1px solid #181827;
    background-color: transparent;
    border-radius: 12px;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: #181827;
    margin-top: 8px;
    white-space: nowrap;
}
.continue-google svg,
.continue-apple svg {
    margin-right: 12px;
}
.initial-screen-data-separator {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    text-align: center;
    color: #999999;
    margin-top: 16px;
    margin-bottom: 12px;
}
.initial-screen-data-separator:before,
.initial-screen-data-separator:after {
    content: '';
    display: block;
    width: 152px;
    height: 1px;
    background: linear-gradient(90deg, rgba(243, 243, 243, 0) 0%, #F3F3F3 48.23%, rgba(243, 243, 243, 0) 100%);
}



.initial-screen-decor-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    align-items: center;
    width: 65%;
    min-width: 840px;
}
.language-wrapper {
    position: absolute;
    top: 48px;
    right: 56px;
}
.language-wrapper:after {
    content: '';
    display: block;
    position: absolute;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 7.5L10 12.5L15 7.5' stroke='%2394B4A4' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    width: 20px;
    height: 20px;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
}
.language-wrapper:focus-within:after {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 12.5L10 7.5L15 12.5' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}
#language {
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: #94B4A4;
    padding: 12px 39px 12px 17px;
    background-color: transparent;
    border-radius: 12px;
    border: 1px solid #C9D9D1;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
}
#language:focus {
    background-color: #94B4A4;
    color: #FFFFFF;
}
#language option {
    background-color: white;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: #181827;
}
.initial-screen-decor-wrapper svg,
.initial-screen-decor-wrapper img {
    margin-right: -30px;
    width: min(100%, 100vh);
    min-width: 808px;
    height: auto;
}
.initial-screen-decor-title {
    font-weight: 800;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    color: #181827;
    margin-top: -20px;
    margin-bottom: 24px;
}
.initial-screen-decor-text {
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    text-align: center;
    color: #999999;
    max-width: 438px;
}

.initial-screen-support-link-wrapper {
    position: absolute;
    top: 48px;
    right: 48px;
}
.login-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}
.login-field-wrapper {
    padding: 10px;
}

.forgot-password {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    text-align: right;
    color: #9A975A;
    -webkit-text-decoration-line: none;
            text-decoration-line: none;
    display: block;
    margin-top: 8px;
    margin-bottom: 32px;
}

.new-user {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
}
.new-user-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #181827;
    margin-right: 6px;
}
.new-user-link {
    display: flex;
    align-items: flex-end;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    color: #94B4A4;
    -webkit-text-decoration-line: none;
            text-decoration-line: none;
}
.new-user-link svg {
    margin-left: 4px;
    margin-bottom: 1px;
}
.field-error-text {
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    color: #C25E6B;
    margin-top: 4px;
}
.notification {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    left: 0;
    bottom: 48px;
    width: 290px;
    padding: 35px 24px 24px;
    background-color: #FFFFFF;
    box-shadow: 0 6px 24px rgba(173, 180, 173, 0.35);
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    z-index: 1300;
}
.notification:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 12px;
    background: radial-gradient(162.96% 359.81% at 14.53% 64.81%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #9A975A;
    border-top-right-radius: 12px;
}
.notification-error:before {
    background: radial-gradient(162.96% 359.81% at 14.53% 64.81%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #C25E6B;
}
.notification-target-text {
    display: inline-block;
    margin-right: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #94B4A4;
}
.notification-target-action {
    display: inline-block;
    max-width: 197px;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #181827;
}
.notification-error .notification-target-action {
    color: #C25E6B;
}
.notification svg {
    cursor: pointer;
    float: right;
    width: 16px;
    height: 16px;
}
.notification path {
    stroke: #181827;
}
.support-link-component {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px 28px;
    color: white;
    background-color: #94B4A4;
    border-radius: 48px;
    text-decoration: none;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
}
.support-link-component:hover {
    box-shadow: 0 6px 10px rgba(162, 198, 195, 0.35);
}
.support-link-component svg {
    margin-right: 8px;
}
.support-link-component path {
    stroke: white;
}
.cbm-button-wrapper {
    width: 100%;
}
.cbm-button-wrapper--loading {
    position: relative;
}
.cbm-button-wrapper--loading:after {
    content: '';
    position: absolute;
    display: block;
    width: 24px;
    height: 24px;
    top: 50%;
    right: -34px;
    transform: translateY(-50%);
    background-image: url("data:image/svg+xml,%3Csvg class='loader-icon' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' style='display:block;' width='24px' height='24px' viewBox='0 0 100 100' preserveAspectRatio='xMidYMid'%3E%3Ccircle cx='50' cy='50' r='30' stroke-width='10' stroke='%2394b4a4' stroke-dasharray='47.12388980384689 47.12388980384689' fill='none' stroke-linecap='round'%3E%3CanimateTransform attributeName='transform' type='rotate' repeatCount='indefinite' dur='1s' keyTimes='0;1' values='0 50 50;360 50 50'%3E%3C/animateTransform%3E%3C/circle%3E%3C/svg%3E");
}

.cbm-button {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    padding: 12px 31px;
    background-color: transparent;
    border: 1px solid transparent;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
}

.cbm-button-full-width {
    width: 100%;
}

.cbm-button-success {
    background-color: #94B4A4;
    border-color: #94B4A4;
    color: white;
}
.cbm-button-success:hover {
    box-shadow: 0 6px 10px rgba(162, 198, 195, 0.35);
}

.cbm-button-outline {
    border-color: #94B4A4;
    color: #94B4A4;
}

.cbm-button--disabled {
    background-color: #F0F0F0;
    border-color: #F0F0F0;
    color: white;
    pointer-events: none;
}
.otp-number-title {
    font-weight: 800;
    font-size: 26px;
    line-height: 36px;
    text-align: center;
    color: #181827;
    margin-top: 24px;
    margin-bottom: 48px;
}

.otp-number-next-button-wrapper {
    margin-top: 48px;
}

.otp-number-consent {
    margin: 12px auto 0;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    text-align: center;
    color: #999;
    max-width: 326px;
}
.otp-number-consent a {
    color: #9a975a;
}

/* @todo: old flow
.otp-number-component .arrow,
.otp-number-component .country-list {
    display: none;
}
.otp-number-component .selected-flag {
    cursor: auto;
    background-image: none !important;
}*/

.otp-sms-phone-text {
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    text-align: center;
    color: #181827;
    margin-bottom: 32px;
}
.otp-sms-phone-number {
    font-weight: 700;
}

.otp-inputs {
    display: flex;
    justify-content: center;
}
.otp-inputs .otp-input {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 48px;
    border: 1px solid #C9C9C9;
    border-radius: 12px;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #94B4A4;
    margin-right: 24px;
    text-align: center;
}
.otp-input:last-of-type {
    margin-right: 0;
}
.otp-inputs .otp-input--error {
    border-color: #C25E6B;
    color: #C25E6B;
}

.otp-cta-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #999999;
    margin-top: 12px;
}

.otp-resend-code-text {
    margin-top: 32px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #181827;
}

.otp-timer {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #C9C9C9;
    margin-top: 6px;
}

.otp-resend-code-button {
    display: block;
    margin: 6px auto 0;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #94B4A4;
    background-color: transparent;
}

.otp-next-button-wrapper {
    margin-top: 32px;
    margin-bottom: 12px;
}
.sign-up-password-label {
    margin-bottom: 32px;
}
.consent {
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    text-align: center;
    color: #999999;
    margin-top: 12px;
    max-width: 315px;
}
.consent a {
    color: #9A975A;
}
.consent-and {
    display: inline-block;
    margin: 0 4px;
}


.registration-popup-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    height: 100%;
}
.registration-popup-wrapper > svg {
    width: 240px;
    height: 24px;
}
.registration-popup-wrapper .popup {
    width: 480px;
    padding: 40px 54px;
    margin-top: 40px;
}
.sign-up-highlight {
    color: #214C4F;
}
.didnt-receive {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #181827;
    margin-bottom: 8px;
}
.sign-up-resend {
    background-color: transparent;
    border: none;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #94B4A4;
}
.valid-for {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #C9C9C9;
}
.popup-background {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1001;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.5);
}
.popup-content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.popup-wrapper {
    overflow: visible;
}
.popup {
    position: relative;
    padding: 40px 77px 38px;
    background-color: #FFFFFF;
    box-shadow: 0 8px 24px rgba(177, 183, 177, 0.35);
    border-radius: 24px;
    width: 479px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1002;
}
.popup-content-wrapper .popup {
    box-shadow: 0 6px 24px rgba(233, 235, 233, 0.35);
}
.popup-ledge {
    background-color: #FFFFFF;
    box-shadow: 0 8px 24px rgba(177, 183, 177, 0.35);
    height: 24px;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    margin: 0 32px;
}
.popup-content-wrapper .popup-ledge {
    box-shadow: 0 6px 24px rgba(233, 235, 233, 0.35);
}
.popup-title {
    font-weight: 800;
    font-size: 26px;
    line-height: 36px;
    color: #181827;
    margin-top: 12px;
    text-align: center;
}
.popup-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #999999;
    text-align: center;
    margin: 8px 0 40px;
}
.popup-header {
    display: flex;
    align-items: center;
    width: 479px;
    padding: 32px 32px 62px;
    margin-bottom: -30px;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    background-color: #9A975A;
    background-image: url("data:image/svg+xml,%3Csvg width='132' height='121' viewBox='0 0 132 121' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='115.5' cy='76.5' r='76.5' fill='white' fill-opacity='0.1'/%3E%3Ccircle cx='12' cy='12' r='12' fill='white' fill-opacity='0.1'/%3E%3C/svg%3E%0A");
    background-position: bottom right;
    background-repeat: no-repeat;
}
.popup-header-title {
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
    color: white;
    margin-left: 12px;
}
.popup-header-date-wrapper {
    color: white;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-left: auto;
}
.popup-header-time {
    text-align: right;
}
.popup.popup--patient-notification {
    padding-left: 48px;
    padding-right: 48px;
}
.tab-wrapper {
    background-color: #FFFFFF;
    border: 1px solid #F0F0F0;
    border-radius: 16px;
    max-width: 662px;
    width: 100%;
}
.settings-content-wrapper .tab-wrapper {
    margin: 0 auto 16px;
}


.tab-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 21px 32px 22px;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.tab-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #181827;
}
.tab-wrapper--active .tab-header svg {
    transform: rotate(180deg);
}


.tab-content-wrapper {
    padding: 0 32px 32px;
}
.drop-down-wrapper {
    position: relative;
}

.delete-workplace {
    display: flex;
    align-items: center;
    background-color: transparent;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: #C25E6B;
    margin-top: 24px;
}
.delete-workplace svg {
    margin-right: 8px;
}

.add-workplace-wrapper {
    display: flex;
    align-items: center;
}
.add-workplace-wrapper svg {
    margin-right: 12px;
}
.add-workplace-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #181827;
    max-width: 348px;
}
.add-workplace-button-text {
    text-align: center;
    width: 100%;
}

.jobs-search .search-result-container {
    padding: 16px 13px;
    max-height: 250px;
    overflow: auto;
}
.jobs-search .search-result-item {
    margin-bottom: 16px;
}
.jobs-search .search-result-img-wrapper {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #F0F0F0;
    border-radius: 50%;
    margin-right: 12px;
    flex: 0 0 32px;
}
.jobs-search .search-result-img-wrapper svg {
    flex: 0 0 16px;
    width: 16px;
    height: 16px;
    margin: 0;
}
.jobs-search .search-result-title {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #181827;
    margin-bottom: 4px;
}
.jobs-search .search-result-item:hover .search-result-title {
    color: #94B4A4;
}
.jobs-search .search-result-subtitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #999999;
}

#experience.tab-input {
    width: 78px;
    text-align: center;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.work-experience-note {
    display: inline-block;
    width: 78px;
    padding: 13px 27px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #181827;
    border: 1px solid #F0F0F0;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    margin-left: -1px;
}
#experience.tab-input:hover ~ .work-experience-note {
    border-color: #C9C9C9;
}
#experience.tab-input:focus ~ .work-experience-note {
    border-color: #94B4A4;
}
.field-wrapper--error #experience.tab-input ~ .work-experience-note {
    border-color: #C25E6B;
}
.search-input-wrapper {
    display: flex;
    align-items: center;
    padding: 13px 14px;
    width: 400px;
    min-width: 283px;
    max-width: 400px;
    border: 1px solid #F0F0F0;
    border-radius: 12px;
    cursor: text;
}
@media (max-width: 1300px) {
    .search-input-wrapper.patients {
        width: calc(100% - 80px);
        max-width: 100%;
        margin: 16px 40px 0;
    }
}
.search-input-wrapper:focus-within {
    border-color: #94B4A4 !important;
}
.search-input {
    width: 100%;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #181827;
    border: none;
    margin-left: 12px;
    border-radius: 0;
    background-color: transparent;
}
.search-result-container-wrapper {
    display: none;
    position: absolute;
    padding-top: 8px;
    width: 100%;
    z-index: 10;
}
@media (max-width: 1300px) {
    .search-result-container-wrapper {
        width: calc(100% - 48px);
        margin: 0 24px;
    }
}
.drop-down-wrapper:focus-within .search-result-container-wrapper,
.search-result-container-wrapper:hover {
    display: block;
}
.search-result-container {
    background-color: #FFFFFF;
    border: 1px solid #F0F0F0;
    box-shadow: 0 6px 24px rgba(233, 235, 233, 0.7);
    border-radius: 12px;
    padding: 16px;
}
.search-result-item {
    margin: 12px 0;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #181827;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.search-result-item:hover {
    color: #94B4A4;
}
.change-language-buttons-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.change-language-button {
    position: relative;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: #181827;
    background-color: transparent;
    padding: 0;
}
.change-language-button:hover {
    color: #94B4A4;
}
.change-language-button--active {
    color: #94B4A4;
    padding-right: 26px;
}
.change-language-button--active:after {
    content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.16602 9.99998L8.33268 14.1666L16.666 5.83331' stroke='%2394B4A4' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    display: block;
    height: 20px;
    width: 20px;
    position: absolute;
    right: 0;
    top: 1px;
}
.change-language-separator {
    display: block;
    width: .5px;
    height: 18px;
    transform: scaleX(2);
    background-color: #181827;
    margin: 0 16px;
}
.settings-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 48px 40px;
    background-color: #FFFFFF;
    box-shadow: 0 6px 24px rgba(233, 235, 233, 0.35);
    border-top-left-radius: 24px;
}
@media (max-width: 1300px) {
    .settings-header {
        padding: 24px;
        border-bottom-left-radius: 24px;
    }
}

.settings-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background-color: #FFFFFF;
    box-shadow: 0 6px 24px rgba(233, 235, 233, 0.8);
    border-radius: 50%;
}
.settings-header-title {
    font-weight: 800;
    font-size: 32px;
    line-height: 36px;
    color: #181827;
    margin-left: 12px;
}
.settings-content-wrapper {
    padding: 48px 24px;
}

.tab-section {
    border-top: 1px solid #F0F0F0;
    padding: 24px 0;
}
.tab-content-wrapper .tab-section:last-of-type {
    padding-bottom: 0;
}
.tab-label {
    display: block;
    margin-bottom: 16px;
    max-width: 400px;
}
.tab-label-text {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #181827;
    margin-bottom: 12px;
}
.tab-select,
.tab-input {
    width: 400px;
    padding: 13px 18px;
    background-color: #FFFFFF;
    border: 1px solid #F0F0F0;
    border-radius: 12px;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #94B4A4;
}
.tab-input:hover {
    border-color: #C9C9C9;
}
.tab-input:focus {
    border-color: #94B4A4;
}
.tab-select {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 7.5L10 12.5L15 7.5' stroke='%2394B4A4' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center right 14px;
}
.tab-select.tab-select--not-chosen {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 7.5L10 12.5L15 7.5' stroke='%23999999' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    color: #999999;
    font-weight: 500;
}
.tab-label .tab-select ~ .field-wrapper,
.tab-label .field-wrapper ~ .field-wrapper {
    margin-top: 12px;
}
.field-wrapper--error .tab-input {
    border-color: #C25E6B;
    color: #C25E6B;
}
.field-inner-wrapper {
    display: inline-block;
    position: relative;
}
.field-wrapper--error .field-inner-wrapper:after {
    content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z' fill='%23C25E6B' stroke='%23C25E6B' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10 6.66675V10.0001' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10 13.3333H10.0073' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    right: -30px;
    top: 50%;
    transform: translateY(-50%);
}
.tab-button {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 229px;
    width: 100%;
    padding: 13px 17px;
    text-align: center;
    border-radius: 12px;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    border: 1px solid #94B4A4;
    color: #94B4A4;
    position: relative;
    overflow: hidden;
}
.tab-button:hover {
    color: white;
    border: none;
    padding: 14px 6px;
}
.tab-button.tab-button--save {
    color: white;
    background-color: #94B4A4;
    border-color: transparent;
}
.tab-button.tab-button--save:hover {
    box-shadow: 0 6px 10px rgba(162, 198, 195, 0.35);
}
.tab-button.tab-button--delete {
    color: white;
    background: #C25E6B;
    border-color: transparent;
    margin: 0 auto;
}
.tab-button.tab-button--delete:hover {
    box-shadow: 0 6px 10px rgba(162, 198, 195, 0.35);
}
.tab-button.tab-button--transparent {
    background-color: transparent;
    border: 1px solid #94B4A4;
    color: #94B4A4;
    justify-content: flex-start;
    margin-top: 16px;
}
.tab-button.tab-button--transparent:hover {
    border: none;
    padding: 14px 18px;
    background: #94B4A4;
    color: white;
}
.tab-button--transparent svg {
    position: absolute;
    top: 14px;
    left: 17px;
}
.tab-button--transparent path {
    stroke: #94B4A4;
}
.tab-button--transparent:hover path {
    stroke: white;
}

.popup-email {
    color: #94B4A4;
}
#photo {
    display: none;
}
.photo-label {
    display: block;
    background-image: url(/static/media/change-photo-empty-icon.1ee9b857.svg);
    width: 150px;
    height: 168px;
    padding-bottom: 168px;
    background-color: transparent;
    color: transparent;
    cursor: pointer;
}
.photo-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #999999;
    margin-top: 16px;
}
.photo-container {
    display: flex;
    justify-content: flex-start;
}
.photo-wrapper {
    background-image: url("data:image/svg+xml,%3Csvg width='150' height='150' viewBox='0 0 150 150' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='75' cy='75' r='74.5' stroke='url(%23paint0_linear)'/%3E%3Ccircle cx='75' cy='75' r='65' fill='url(%23paint1_linear)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear' x1='75' y1='0' x2='75' y2='140' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2394B4A4'/%3E%3Cstop offset='1' stop-color='%2394B4A4' stop-opacity='0'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint1_linear' x1='75' y1='10' x2='75' y2='140' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23EFF4F1'/%3E%3Cstop offset='1' stop-color='%23EFF4F1' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
}
.photo-wrapper:after {
    content: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='24' cy='24' r='24' fill='%2394B4A4'/%3E%3Cpath d='M17.334 30.6667H20.6673L29.4173 21.9167C29.8593 21.4747 30.1077 20.8751 30.1077 20.25C30.1077 19.6249 29.8593 19.0254 29.4173 18.5834C28.9753 18.1413 28.3758 17.893 27.7507 17.893C27.1255 17.893 26.526 18.1413 26.084 18.5834L17.334 27.3334V30.6667Z' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M25.25 19.4167L28.5833 22.7501' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    display: block;
    width: 48px;
    height: 48px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
}
.photo {
    border-radius: 50%;
    width: 130px;
    height: 130px;
}
.remove-photo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 34px;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: #C25E6B;
    background-color: transparent;
}
.remove-photo svg {
    margin-right: 8px;
}
.photo-error {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 20px;
    margin-top: 67px;
    margin-left: 27px;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    color: #C25E6B;
}
.photo-error svg {
    margin-right: 6px;
}
.new-password-hint {
    display: inline-block;
    background-color: #F0F0F0;
    border-radius: 24px;
    padding: 6px 8px;
    margin: 4px 4px 0 0;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    color: #999999;
    position: relative;
}
.new-password-hint--active {
    padding-left: 28px;
    color: #94B4A4;
}
.new-password-hint--active:before {
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.33398 7.99984L6.66732 11.3332L13.334 4.6665' stroke='%2394B4A4' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 6px;
    left: 8px;
}
.password svg {

}
.pass-type-toggle {
    cursor: pointer;
    position: absolute;
    top: 14px;
    right: 14px;
    width: 20px;
    height: 20px;
}
input[type="password"] ~ .pass-type-toggle {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.0007 11.6667C10.9211 11.6667 11.6673 10.9205 11.6673 10C11.6673 9.07957 10.9211 8.33337 10.0007 8.33337C9.08018 8.33337 8.33398 9.07957 8.33398 10C8.33398 10.9205 9.08018 11.6667 10.0007 11.6667Z' stroke='%23999999' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M18.3327 9.99996C16.1102 13.8891 13.3327 15.8333 9.99935 15.8333C6.66602 15.8333 3.88852 13.8891 1.66602 9.99996C3.88852 6.11079 6.66602 4.16663 9.99935 4.16663C13.3327 4.16663 16.1102 6.11079 18.3327 9.99996Z' stroke='%23999999' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}
input[type="text"] ~ .pass-type-toggle {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.5 2.5L17.5 17.5' stroke='%23999999' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8.8207 8.82239C8.50797 9.1349 8.33219 9.55885 8.33203 10.001C8.33188 10.4431 8.50736 10.8672 8.81987 11.1799C9.13238 11.4926 9.55633 11.6684 9.99845 11.6686C10.4406 11.6687 10.8646 11.4932 11.1774 11.1807' stroke='%23999999' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.80185 4.47084C8.51636 4.26644 9.25618 4.16404 9.99935 4.16668C13.3327 4.16668 16.1102 6.11084 18.3327 10C17.6843 11.1342 16.9893 12.1033 16.2468 12.9067M14.4635 14.4575C13.1043 15.3742 11.6177 15.8333 9.99935 15.8333C6.66602 15.8333 3.88852 13.8892 1.66602 10C2.80685 8.00418 4.09352 6.52084 5.52602 5.54918' stroke='%23999999' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

.caps-lock {
    position: absolute;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    color: #C25E6B;
    top: 50%;
    transform: translate(100%, -50%);
    right: -12px;
}
.field-wrapper--error .caps-lock {
    right: -38px;
}
.prefer-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #181827;
    margin-bottom: 16px;
}
.radio-button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 400px;
    background: transparent;
    padding: 0;
    margin-bottom: 12px;
}
.radio-button:last-of-type {
    margin-bottom: 0;
}
.radio-button-img-wrapper {
    width: 32px;
    height: 32px;
    background-color: #C9C9C9;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
}
.radio-button--active .radio-button-img-wrapper,
.radio-button:hover .radio-button-img-wrapper {
    background-color: #94B4A4;
}
.radio-button-img-wrapper svg {
    width: 16px;
    height: 16px;
}
.mail path:first-of-type {
    fill: white;
    stroke: white;
}
.mail path:last-of-type {
    stroke: #C9C9C9;
}
.radio-button-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #181827;
}
.radio-button--active .radio-button-checkmark:after {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    background-color: #94B4A4;
    border-radius: 50%;
}
.radio-button-checkmark {
    position: relative;
    margin-left: auto;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid #C9C9C9;
}


.intl-tel-input.allow-dropdown .selected-flag {
    width: 74px;
    border-right: 1px solid #F0F0F0;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 7.5L10 12.5L15 7.5' stroke='%2394B4A4' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center right 5px;
}
.intl-tel-input.allow-dropdown.expanded .selected-flag {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 12.5L10 7.5L15 12.5' stroke='%2394B4A4' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}
.intl-tel-input.allow-dropdown .flag-container:hover .selected-flag {
    background-color: transparent;
}
.intl-tel-input.allow-dropdown input.tab-input {
    padding-left: 93px;
}
#root .intl-tel-input .flag-container .arrow:after {
    content: none;
}
.intl-tel-input .country-list {
    width: 400px;
    overflow-x: hidden;
    margin-top: 12px;
    padding: 16px;
    background-color: #FFFFFF;
    border: 1px solid #F0F0F0;
    border-radius: 12px;
}
.intl-tel-input .country-list .country.highlight {
    background-color: transparent;
}
.intl-tel-input .country-list .country-name {
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: #181827;
}
.intl-tel-input .country-list .country.highlight .country-name {
    color: #94B4A4;
}
.tab-button.remove-account {
    max-width: 100% ;
}
.remove-account-cancel {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #94B4A4;
    margin-top: 24px;
    cursor: pointer;
}
.remove-account-buttons-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.gap-12 {
    width: 12px;
    flex: 0 0 12px;
}
.popup-remove-account .popup-text {
    max-width: 250px;
}
.main-nav {
    display: flex;
    flex-direction: column;
}
.main-header .main-nav {
    margin: 40px 0;
}
@media (max-width: 1300px) {
    .main-header .main-nav {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        width: 100%;
        height: 500px;
        min-height: 100vh;
        justify-content: center;
        align-items: center;
        transform: translateY(-100%);
        margin: 0;
        background-image: url(/static/media/mobile-menu-background.5094b81f.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        transition: transform 1s;
        padding-top: 88px;
    }
}
.main-header--menu-opened .main-nav {
    transform: translateY(0);
}
.nav-item {
    display: flex;
    align-items: center;
    position: relative;
    padding: 16px 16px 16px 48px;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    -webkit-text-decoration-line: none;
            text-decoration-line: none;
    color: white;
    border-radius: 16px;
}
@media (max-width: 1300px) {
    .nav-item {
        width: 280px;
        padding: 16px 0;
        justify-content: center;
        font-size: 20px;
        line-height: 27px;
        margin: 8px 0;
    }
}
.nav-item:not(.nav-item--active):hover {
    color: #94B4A4;
}
.nav-item--active {
    font-weight: 600;
    background-color: #FFFFFF;
    color: #181827;
}
.nav-item svg {
    position: absolute;
    left: 16px;
}
.nav-item:not(.nav-item--active):hover path {
    stroke: #94B4A4;
}
.nav-item--active path {
    stroke: #181827;
}
.nav-item-notifications-wrapper {
    position: relative;
}
.nav-item-notifications {
    display: flex;
    justify-content: center;
    align-content: center;
    position: absolute;
    width: 24px;
    height: 24px;
    top: -2.5px;
    right: -53px;
    font-weight: 500;
    font-size: 11px;
    line-height: 24px;
    color: #FFFFFF;
    background-color: #94B4A4;
    border-radius: 50%;
}
@media (max-width: 1300px) {
    .nav-item-notifications {
        width: 20px;
        height: 20px;
        line-height: 20px;
        top: 4px;
        right: -28px;
    }
}
.nav-item-count {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    font-size: 11px;
    line-height: 14px;
    background-color: #94B4A4;
    color: white;
    margin-left: auto;
    border-radius: 50%;
    margin-top: -2px;
    margin-bottom: -2px;
}
@media (max-width: 1300px) {
    .nav-item-count {
        width: 20px;
        height: 20px;
        margin-left: 6px;
        margin-top: 0;
        margin-bottom: 0;
    }
}
.profile-wrapper {
    position: relative;
    width: 100%;
    z-index: 2;
}
@media (max-width: 1300px) {
    .profile-wrapper {
        max-width: 210px;
        margin-left: auto;
    }
}
.profile {
    display: flex;
    align-items: center;
    padding: 15px;
    border: 1px solid rgba(255,255,255,0.1);
    border-radius: 16px;
    cursor: pointer;
}
@media (max-width: 1300px) {
    .profile {
        padding: 10px;
    }
}
.profile-wrapper:hover .profile {
    background-color: rgba(255,255,255,0.1);
    border: 1px solid transparent;
}
.profile-img-wrapper,
.profile-img-wrapper svg,
.profile-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.profile-data {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px 0 5px 10px;
    overflow: hidden;
}
.profile-full-name {
    font-weight: 700;
    font-size: 11px;
    line-height: 15px;
    color: #FFFFFF;
    overflow: hidden;
    width: 100%;
}
.profile-specialization {
    font-weight: 500;
    font-size: 9px;
    line-height: 12px;
    color: #9A975A;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}
.profile-action-wrapper {
    display: none;
    position: absolute;
    padding-top: 8px;
    width: 100%;
}
.profile-action {
    width: 100%;
    border: none;
    padding: 16px 16px 16px 48px;
    background-color: #C25E6B;
    box-shadow: 0 6px 24px rgba(173, 180, 173, 0.35);
    border-radius: 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: left;
    color: white;
    cursor: pointer;
}
.profile-wrapper:hover .profile-action-wrapper,
.profile-action-wrapper:hover {
    display: block;
}
.profile-action svg {
    position: absolute;
    left: 16px;
}
.main-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 290px;
    /*height: clamp(100%, 100vh, 100vh);*/
    /*min-height: 100vh;*/
    height: 100vh;
    /*overflow: hidden;*/
    background-color: #181827;
    background-image: url(/static/media/main-header-background.9fa665e3.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 48px 56px 48px 24px;
}
@media (max-width: 1300px) {
    .main-header {
        max-width: 100%;
        flex: 1 1 100%;
        min-height: auto;
        padding: 14px 24px;
        max-height: 88px;
        z-index: 301;
        position: relative;
        background-image: url(/static/media/main-header-mobile-background.a0a0a3e8.png);
    }
}
.main-header.main-header--menu-opened {
    /*min-height: 100vh;
    height: 100vh;
    justify-content: flex-start;*/
}


/*@media (min-width: 1301px) and (min-height: 600px) {
    .top-fixed {
        position: fixed;
        top: 48px;
        left: 24px;
        min-width: 210px;
    }
    .bottom-fixed {
        position: fixed;
        left: 24px;
        bottom: 48px;
    }
}*/
/*@media (min-width: 1301px) and (min-height: 600px) {
    .main-header {
        position: sticky;
        top: 0;
    }
}*/
@media (min-width: 1301px) {
    .main-header {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
    }
}
@media (min-width: 1301px) and (max-height: 600px) {
    .main-header {
        height: 100%;
    }
}


.main-header .logo-wrapper {
    margin: 24px;
}


.copyright {
    font-weight: 500;
    font-size: 10px;
    line-height: 18px;
    color: rgba(255,255,255,0.5);
}
.main-header .copyright {
    margin: 0 24px;
}


.menu-button {
    position: relative;
    width: 32px;
    height: 32px;
    cursor: pointer;
}
.main-header .menu-button {
    margin-right: 24px;
}
.menu-button:before,
.menu-button:after {
    content: '';
    display: block;
    margin: 0 auto;
    width: 21.5px;
    height: 1.5px;
    background-color: white;
    border-radius: 1px;
    transition: transform, .5s;
}
.menu-button:before {
    transform: translateY(10px);
}
.menu-button:after {
    transform: translateY(20px);
}
.menu-button--active:before {
    transform: rotate(-45deg) translate(-7px, 13px);
}
.menu-button--active:after {
    transform: rotate(45deg) translate(12px, 6px);
}


@media (max-width: 1300px) {
    .main-header .group-wrapper {
        display: flex;
        justify-content: left;
        align-items: center;
        position: relative;
        z-index: 301;
    }
}
.creation-account-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 48px 24px;
}

.creation-account-content-wrapper {
    margin-top: 40px;
    max-width: 480px;
    width: 100%;
}
.creation-account-content-header {
    padding: 32px 40px 24px;
    background-image: url(/static/media/header-waves-bg.e32998d2.svg);
    background-color: #181827;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
}
.creation-account-content-title {
    font-weight: 800;
    font-size: 26px;
    line-height: 36px;
    color: #FFFFFF;
}
.creation-account-content-step-info {
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #9A975A;
    margin-top: 4px;
}
.creation-account-progressbar {
    position: relative;
    height: 8px;
    background-image: url(/static/media/creation-account-progressbar-bg.013d7c25.svg);
}
.creation-account-progressbar-gradient {
    position: absolute;
    display: block;
    height: 8px;
    top: 0;
    left: 0;
    background: radial-gradient(162.96% 359.81% at 14.53% 64.81%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #9A975A;
}
/*.creation-account-progressbar:before {
    content: '';
    position: absolute;
    display: block;
    height: 8px;
    width: 120px;
    background: radial-gradient(162.96% 359.81% at 14.53% 64.81%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #9A975A;
}
.creation-account-progressbar--1:before {
    width: 240px;
}
.creation-account-progressbar--2:before {
    width: 360px;
}
.creation-account-progressbar--3:before {
    width: 480px;
}*/
.creation-account-step-wrapper {
    padding: 40px;
    background-color: #FFFFFF;
    box-shadow: 0 6px 24px rgba(233, 235, 233, 0.35);
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
}
.creation-account-ledge {
    height: 24px;
    width: 90%;
    margin: 0 auto;
    background-color: #FFFFFF;
    box-shadow: 0 6px 24px rgba(233, 235, 233, 0.35);
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
}


.creation-account-separator {
    background: linear-gradient(90deg, rgba(243,243,243,0) 0%, #F3F3F3 50%, rgba(243,243,243,0) 100%);
    height: 1px;
    width: 100%;
    margin: 32px 0 24px;
}
.creation-account-section-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #181827;
}
.creation-account-section-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #999999;
    margin-top: 10px ;
}
.creation-account-controls-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 48px;
}
.creation-account-controls-wrapper button:first-of-type {
    width: 159px;
}
.creation-account-controls-wrapper button:last-of-type {
    width: 229px;
}
.creation-account-back-icon {
    margin-right: 8px;
    height: 22px;
}
.creation-account-label-icon {
    margin-right: 6px;
}
.creation-account-block {
    margin: 26px 0 32px;
}

.creation-account-photo-wrapper {
    margin-top: 32px;
    margin-bottom: 72px;
}
.creation-account-photo-wrapper .photo-label {
    margin: 0 auto;
    background: url(/static/media/creation-account-photo-empty.6d897c4a.svg);
}
.creation-account-photo-wrapper .photo-wrapper {
    margin: 0 auto;
}
.creation-account-step-wrapper--3 .tab-label {
    margin-bottom: 24px;
}
.creation-account-tabs-wrapper {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    height: 39px;
    margin: 32px 0 34px;
}
.creation-account-tabs-wrapper:before {
    content: '';
    position: absolute;
    display: block;
    height: 1px;
    width: 480px;
    bottom: 0;
    left: -40px;
    background-color: #F3F3F3;
}
.creation-account-tab {
    padding-bottom: 15px;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color:  #C9C9C9;
    background-color: transparent;
}
.creation-account-tab:hover {
    color: #94B4A4;
}
.creation-account-tab--chosen {
    position: relative;
    color: #94B4A4;
    font-weight: 800;
}
.creation-account-tab--chosen:before {
    content: '';
    position: absolute;
    display: block;
    height: 5px;
    width: 80px;
    bottom: -3px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #94B4A4;
    border-radius: 4px;
}

.creation-account-card {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.card-number-wrapper .field-wrapper--error .field-inner-wrapper:after {
    right: -166px;
}
.card-number-wrapper,
.card-number-wrapper .tab-input {
    width: 264px;
}
.card-expire-wrapper,
.card-expire-wrapper .tab-input {
    width: 120px;
}
.creation-account-card .tab-label {
    margin-bottom: 0;
}
.patients-search .search-input-wrapper:hover,
.patients-search .search-input-wrapper:focus-within {
    border: 1px solid #C9C9C9;
}
.patients-search .search-input-wrapper svg {
    width: 20px;
    height: 20px;
}
.patients-search .search-input-wrapper svg:first-of-type:last-of-type {
    cursor: text;
}
.patients-search .search-input-wrapper svg:last-of-type {
    cursor: pointer;
}
.patients-search .search-input-wrapper svg path {
    stroke: #94B4A4;
}
.patients-search .search-result-wrapper:first-of-type {
    margin-bottom: 16px;
}
.patients-search .search-result-title {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #181827;
}
.search-result-item-diagnosis {
    line-height: 16px;
    align-items: flex-start;
}
.patients-search .search-result-item img,
.patients-search .search-result-item svg {
    flex: 0 0 40px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 12px;
}
.search-result-small {
    white-space: nowrap;
    margin-right: 3px;
    font-weight: 500;
    font-size: 12px;
    color: #999999;
}
.patient-page .page-header {
    flex-direction: column;
}
.patient-page .patients-search {
    width: 100%;
}
.patient-page .search-input-wrapper {
    width: 100%;
    max-width: none;
}

.bread-crumbs-wrapper {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: -32px;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #181827;
}
@media (max-width: 1300px) {
    .bread-crumbs-wrapper {
        margin-bottom: 0;
    }
}
.bread-crumbs-link {
    color: #999999;
}
.bread-crumbs-more {
    font-size: 16px;
    margin: 0 4px;
}
.patient-card-wrapper {
    position: relative;
}
.patient-card {
    display: flex;
    background-color: #FFFFFF;
    border: 1px solid #F0F0F0;
    border-radius: 24px;
    padding: 32px;
}

.patient-card-info {
    /*width: 33%;*/
    flex-shrink: 0;
    padding-right: 32px;
}
.patient-card-info-inner-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    max-width: 312px;
    min-width: 280px;
    margin: 0 auto;
}
.patient-card-wrapper--small .patient-photo-wrapper {
    margin-right: 32px;
}
.patient-id {
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    color: #181827;
}
.patient-card-wrapper .patient-id {
    position: absolute;
    top: 0;
    left: 0;
}
.patient-card-wrapper--small .patient-id {
    position: relative;
}
.patient-full-name {
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
}
.patient-card-wrapper--small .patient-full-name {
    margin-top: 8px;
    margin-bottom: 8px;
}
.patient-card-wrapper .patient-full-name {
    margin-top: 16px;
}
.patient-card-separator {
    background: linear-gradient(270deg, rgba(240, 240, 240, 0) 0%, #F0F0F0 50.83%, rgba(240, 240, 240, 0) 100%);
    height: 1px;
    width: 100%;
    margin: 0 auto;
}
.patient-card-more-details .patient-card-separator {
    margin-top: 12px;
    margin-bottom: 9px;
}
.patient-card-more-details .patient-card-ua-date-group ~ .patient-card-separator {
    margin-top: 46px;
    margin-bottom: 46px;
}
.patient-card-wrapper .patient-card-separator {
    max-width: 312px;
    margin-top: 18px;
    margin-bottom: 15px;
}
.patient-card-info-items-wrapper {
    align-self: flex-start;
}
.patient-card-info-item-wrapper {
    display: flex;
    margin-top: 12px;
}
.patient-card-info-img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background-color: #FFFFFF;
    box-shadow: 0 6px 24px rgba(233, 235, 233, 0.7);
    margin-right: 12px;
    border-radius: 50%;
}
.patient-card-info-text {
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    color: #181827;
    margin-top: 6px;
}
.patient-card-info-sub-text {
    display: inline-block;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #999999;
    margin-top: 6px;
    margin-left: 4px;
}

.patient-card-more-details {
    display: flex;
    /*width: 67%;*/
    flex-grow: 1;
    background-color: #F7FBF9;
    border-radius: 16px;
    padding: 32px;
    background-image: url(/static/media/patient-card-background.1e1f1fcd.png);
    background-position: center right;
    background-repeat: no-repeat;
    background-size: contain;
}
.patient-card-more-details-inner-wrapper {
    max-width: 314px;
}
.patient-card-more-details-inner-wrapper ~ svg {
    margin: 0 auto;
    transform: translateX(40px);
}
.patient-card-date-group {
    display: flex;
    justify-content: space-between;
}
.patient-card-ua-date-group {
    display: flex;
}
.patient-card-wrapper--small .patient-card-dates-wrapper {
    display: flex;
    align-items: flex-start;
    align-self: center;
    margin-left: auto;
}
.patient-card-wrapper .patient-card-date-wrapper {
    width: 50%;
}
.patient-card-ua-date-group .patient-card-date-wrapper {
    width: auto;
    flex-shrink: 0;
    margin-right: 24px;
}
.patient-card-ua-date-group .patient-card-date-wrapper:last-of-type {
    margin-right: 0;
}
.patient-card-wrapper--small .patient-card-date-wrapper {
    position: relative;
    width: auto;
    padding: 0 16px;
}
.patient-card-wrapper--small .patient-card-date-wrapper:last-of-type {
    padding-right: 0;
}
.patient-card-wrapper--small .patient-card-date-wrapper:after {
    content: '';
    display: block;
    position: absolute;
    width: 1px;
    height: 48px;
    top: 0;
    right: 0;
    background: linear-gradient(0deg, rgba(240, 240, 240, 0) 0%, #F0F0F0 50.83%, rgba(240, 240, 240, 0) 100%);
}
.patient-card-wrapper--small .patient-card-date-wrapper:last-of-type:after {
    content: none;
}
.patient-card-date-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #94B4A4;
    margin-bottom: 8px;
}
.patient-card-date {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #181827;
}
.patient-card-options-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 24px;
}
.patient-card-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 151px;
    height: 61px;
    padding: 16px 20px 16px 18px;
    margin-top: 12px;
    background-color: #FFFFFF;
    border: 1px solid #F0F0F0;
    border-radius: 12px;
}
.patient-card-option-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #999999;
}
.patient-card-option-index {
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    text-align: right;
    color: #181827;
}

.patient-card-expander {
    position: absolute;
    left: 50%;
    bottom: 1px;
    width: 48px;
    height: 24px;
    transform: translate(-50%, 100%);
    background-color: #FFFFFF;
    border-bottom-left-radius: 48px;
    border-bottom-right-radius: 48px;
    border-bottom: 1px solid #F0F0F0;
    border-left: 1px solid #F0F0F0;
    border-right: 1px solid #F0F0F0;
}
.patient-card-expander:after {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 50%;
    transform: translateX(-50%);
    width: 12px;
    height: 8px;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 6.5L6 1.5L11 6.5' stroke='%239A975A' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}
.patient-card-wrapper--small .patient-card-expander:after {
    transform: translateX(-50%) rotate(-180deg);
}
.patient-card-expander-text {
    position: absolute;
    left: 50%;
    bottom: -45px;
    transform: translateX(-50%);
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    color: #9A975A;
}

.patient-card--tablet {
    flex-wrap: wrap;
}
.patient-top {
    flex: 100% 1;
    width: 100%;
}
.patient-card--tablet .patient-id {
    top: 24px;
    left: 24px;
}
.patient-top .patient-full-name {
    text-align: center;
}
.patient-card--tablet .patient-more-details {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex: 100% 1;
    width: 100%;
    margin-top: 18px;
}
.patient-card--tablet .patient-more-details:before {
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    flex: 100% 1;
    background: linear-gradient(270deg, rgba(240, 240, 240, 0) 0%, #F0F0F0 50.83%, rgba(240, 240, 240, 0) 100%);
}
.patient-card--tablet .patient-card-info-items-wrapper {
    position: relative;
    flex: 58% 0;
    width: 58%;
}
.patient-card--tablet .patient-card-info-items-wrapper:after {
    content: '';
    display: block;
    position: absolute;
    width: 1px;
    height: 100%;
    right: 0;
    top: 0;
    background: linear-gradient(270deg, rgba(240, 240, 240, 0) -25%, #F0F0F0 100%);
}
.patient-card--tablet .patient-options-wrapper {
    flex: 42% 0;
    width: 42%;
    padding: 24px 0 0 24px;
}
.patient-card--tablet .patient-option-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
}
.patient-card--tablet .patient-option-wrapper:last-of-type {
    margin-bottom: 0;
}
.patient-card--tablet .patient-option-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #999999;
}
.patient-card--tablet .patient-option-value {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #181827;
}
.chart-small-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
}
.chart-small-canvas-wrapper {
    display: inline-block;
    border-radius: 12px;
    overflow: hidden;
}
.chart-small-step-over {
    display: inline-block;
    position: absolute;
    white-space: nowrap;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 5px 14px 5px 26px;
    background-color: #C9C9C9;
    border-radius: 60px;
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    color: #FFFFFF;
}
.chart-small-step-over:before {
    content: '';
    position: absolute;
    display: block;
    width: 12px;
    height: 12px;
    top: 10px;
    left: 8px;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.5 6L5 8.5L10 3.5' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}
.chart-small-weeks-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translate(-50%, 100%);
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    color: #94B4A4;
    white-space: nowrap;
}
.chart-small-wrapper--over .chart-small-weeks-wrapper {
    color: #C9C9C9;
}
.chart-small-wrapper--next .chart-small-weeks-wrapper {
    color: #214C4F;
}
.chart-small-weeks-wrapper svg {
    margin-right: 4px;
}
.chart-small-wrapper--over path {
    stroke: #C9C9C9;
}
.chart-small-wrapper--next path {
    stroke: #214C4F;
}
.chart-small-step-current {
    position: absolute;
}
.chart-small-step-current:before {
    content: '';
    position: absolute;
    display: block;
    width: 16px;
    height: 83px;
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='83' viewBox='0 0 16 83' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 16L8 83' stroke='%239A975A' stroke-dasharray='3 3'/%3E%3Ccircle cx='8' cy='8' r='7.5' fill='%239A975A' stroke='%239A975A'/%3E%3Ccircle cx='8' cy='8' r='3' fill='white'/%3E%3C/svg%3E%0A");
}
.chart-small-step-current-text {
    position: absolute;
    text-align: center;
    top: -22px;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    color: #9A975A;
    transform: translate(-50%, -100%);
    white-space: nowrap;
}
.chart-small-step-current-text--offset-left {

}
.c100 {
    position: relative;
    width: 26px;
    height: 26px;
    border-radius: 50%;
}
.c100 .slice {
    position: absolute;
    width: 26px;
    height: 26px;
    clip: rect(0, 26px, 26px, 13px);
}
.c100.p51 .slice, .c100.p52 .slice, .c100.p53 .slice, .c100.p54 .slice, .c100.p55 .slice, .c100.p56 .slice, .c100.p57 .slice, .c100.p58 .slice, .c100.p59 .slice, .c100.p60 .slice, .c100.p61 .slice, .c100.p62 .slice, .c100.p63 .slice, .c100.p64 .slice, .c100.p65 .slice, .c100.p66 .slice, .c100.p67 .slice, .c100.p68 .slice, .c100.p69 .slice, .c100.p70 .slice, .c100.p71 .slice, .c100.p72 .slice, .c100.p73 .slice, .c100.p74 .slice, .c100.p75 .slice, .c100.p76 .slice, .c100.p77 .slice, .c100.p78 .slice, .c100.p79 .slice, .c100.p80 .slice, .c100.p81 .slice, .c100.p82 .slice, .c100.p83 .slice, .c100.p84 .slice, .c100.p85 .slice, .c100.p86 .slice, .c100.p87 .slice, .c100.p88 .slice, .c100.p89 .slice, .c100.p90 .slice, .c100.p91 .slice, .c100.p92 .slice, .c100.p93 .slice, .c100.p94 .slice, .c100.p95 .slice, .c100.p96 .slice, .c100.p97 .slice, .c100.p98 .slice, .c100.p99 .slice, .c100.p100 .slice {
    clip: rect(auto, auto, auto, auto) !important;
}
.c100 .bar {
    position: absolute;
    border: 3px solid;
    width: 26px;
    height: 26px;
    clip: rect(0, 13px, 26px, 0);
    border-radius: 50%;
    transform: rotate(0deg);
}
.c100 .fill {
    position: absolute;
    border: 3px solid;
    width: 26px;
    height: 26px;
    clip: rect(0, 13px, 26px, 0);
    border-radius: 50%;
}
.c100.p51 .fill, .c100.p52 .fill, .c100.p53 .fill, .c100.p54 .fill, .c100.p55 .fill, .c100.p56 .fill, .c100.p57 .fill, .c100.p58 .fill, .c100.p59 .fill, .c100.p60 .fill, .c100.p61 .fill, .c100.p62 .fill, .c100.p63 .fill, .c100.p64 .fill, .c100.p65 .fill, .c100.p66 .fill, .c100.p67 .fill, .c100.p68 .fill, .c100.p69 .fill, .c100.p70 .fill, .c100.p71 .fill, .c100.p72 .fill, .c100.p73 .fill, .c100.p74 .fill, .c100.p75 .fill, .c100.p76 .fill, .c100.p77 .fill, .c100.p78 .fill, .c100.p79 .fill, .c100.p80 .fill, .c100.p81 .fill, .c100.p82 .fill, .c100.p83 .fill, .c100.p84 .fill, .c100.p85 .fill, .c100.p86 .fill, .c100.p87 .fill, .c100.p88 .fill, .c100.p89 .fill, .c100.p90 .fill, .c100.p91 .fill, .c100.p92 .fill, .c100.p93 .fill, .c100.p94 .fill, .c100.p95 .fill, .c100.p96 .fill, .c100.p97 .fill, .c100.p98 .fill, .c100.p99 .fill, .c100.p100 .bar:after, .c100.p100 .fill {
    transform: rotate(180deg);
}
.c100.p1 .bar {
    transform: rotate(3.6deg);
}
.c100.p2 .bar {
    transform: rotate(7.2deg);
}
.c100.p3 .bar {
    transform: rotate(10.8deg);
}
.c100.p4 .bar {
    transform: rotate(14.4deg);
}
.c100.p5 .bar {
    transform: rotate(18deg);
}
.c100.p6 .bar {
    transform: rotate(21.6deg);
}
.c100.p7 .bar {
    transform: rotate(25.2deg);
}
.c100.p8 .bar {
    transform: rotate(28.8deg);
}
.c100.p9 .bar {
    transform: rotate(32.4deg);
}
.c100.p10 .bar {
    transform: rotate(36deg);
}
.c100.p11 .bar {
    transform: rotate(39.6deg);
}
.c100.p12 .bar {
    transform: rotate(43.2deg);
}
.c100.p13 .bar {
    transform: rotate(46.8deg);
}
.c100.p14 .bar {
    transform: rotate(50.4deg);
}
.c100.p15 .bar {
    transform: rotate(54deg);
}
.c100.p16 .bar {
    transform: rotate(57.6deg);
}
.c100.p17 .bar {
    transform: rotate(61.2deg);
}
.c100.p18 .bar {
    transform: rotate(64.8deg);
}
.c100.p19 .bar {
    transform: rotate(68.4deg);
}
.c100.p20 .bar {
    transform: rotate(72deg);
}
.c100.p21 .bar {
    transform: rotate(75.6deg);
}
.c100.p22 .bar {
    transform: rotate(79.2deg);
}
.c100.p23 .bar {
    transform: rotate(82.8deg);
}
.c100.p24 .bar {
    transform: rotate(86.4deg);
}
.c100.p25 .bar {
    transform: rotate(90deg);
}
.c100.p26 .bar {
    transform: rotate(93.6deg);
}
.c100.p27 .bar {
    transform: rotate(97.2deg);
}
.c100.p28 .bar {
    transform: rotate(100.8deg);
}
.c100.p29 .bar {
    transform: rotate(104.4deg);
}
.c100.p30 .bar {
    transform: rotate(108deg);
}
.c100.p31 .bar {
    transform: rotate(111.6deg);
}
.c100.p32 .bar {
    transform: rotate(115.2deg);
}
.c100.p33 .bar {
    transform: rotate(118.8deg);
}
.c100.p34 .bar {
    transform: rotate(122.4deg);
}
.c100.p35 .bar {
    transform: rotate(126deg);
}
.c100.p36 .bar {
    transform: rotate(129.6deg);
}
.c100.p37 .bar {
    transform: rotate(133.2deg);
}
.c100.p38 .bar {
    transform: rotate(136.8deg);
}
.c100.p39 .bar {
    transform: rotate(140.4deg);
}
.c100.p40 .bar {
    transform: rotate(144deg);
}
.c100.p41 .bar {
    transform: rotate(147.6deg);
}
.c100.p42 .bar {
    transform: rotate(151.2deg);
}
.c100.p43 .bar {
    transform: rotate(154.8deg);
}
.c100.p44 .bar {
    transform: rotate(158.4deg);
}
.c100.p45 .bar {
    transform: rotate(162deg);
}
.c100.p46 .bar {
    transform: rotate(165.6deg);
}
.c100.p47 .bar {
    transform: rotate(169.2deg);
}
.c100.p48 .bar {
    transform: rotate(172.8deg);
}
.c100.p49 .bar {
    transform: rotate(176.4deg);
}
.c100.p50 .bar {
    transform: rotate(180deg);
}
.c100.p51 .bar {
    transform: rotate(183.6deg);
}
.c100.p52 .bar {
    transform: rotate(187.2deg);
}
.c100.p53 .bar {
    transform: rotate(190.8deg);
}
.c100.p54 .bar {
    transform: rotate(194.4deg);
}
.c100.p55 .bar {
    transform: rotate(198deg);
}
.c100.p56 .bar {
    transform: rotate(201.6deg);
}
.c100.p57 .bar {
    transform: rotate(205.2deg);
}
.c100.p58 .bar {
    transform: rotate(208.8deg);
}
.c100.p59 .bar {
    transform: rotate(212.4deg);
}
.c100.p60 .bar {
    transform: rotate(216deg);
}
.c100.p61 .bar {
    transform: rotate(219.6deg);
}
.c100.p62 .bar {
    transform: rotate(223.2deg);
}
.c100.p63 .bar {
    transform: rotate(226.8deg);
}
.c100.p64 .bar {
    transform: rotate(230.4deg);
}
.c100.p65 .bar {
    transform: rotate(234deg);
}
.c100.p66 .bar {
    transform: rotate(237.6deg);
}
.c100.p67 .bar {
    transform: rotate(241.2deg);
}
.c100.p68 .bar {
    transform: rotate(244.8deg);
}
.c100.p69 .bar {
    transform: rotate(248.4deg);
}
.c100.p70 .bar {
    transform: rotate(252deg);
}
.c100.p71 .bar {
    transform: rotate(255.6deg);
}
.c100.p72 .bar {
    transform: rotate(259.2deg);
}
.c100.p73 .bar {
    transform: rotate(262.8deg);
}
.c100.p74 .bar {
    transform: rotate(266.4deg);
}
.c100.p75 .bar {
    transform: rotate(270deg);
}
.c100.p76 .bar {
    transform: rotate(273.6deg);
}
.c100.p77 .bar {
    transform: rotate(277.2deg);
}
.c100.p78 .bar {
    transform: rotate(280.8deg);
}
.c100.p79 .bar {
    transform: rotate(284.4deg);
}
.c100.p80 .bar {
    transform: rotate(288deg);
}
.c100.p81 .bar {
    transform: rotate(291.6deg);
}
.c100.p82 .bar {
    transform: rotate(295.2deg);
}
.c100.p83 .bar {
    transform: rotate(298.8deg);
}
.c100.p84 .bar {
    transform: rotate(302.4deg);
}
.c100.p85 .bar {
    transform: rotate(306deg);
}
.c100.p86 .bar {
    transform: rotate(309.6deg);
}
.c100.p87 .bar {
    transform: rotate(313.2deg);
}
.c100.p88 .bar {
    transform: rotate(316.8deg);
}
.c100.p89 .bar {
    transform: rotate(320.4deg);
}
.c100.p90 .bar {
    transform: rotate(324deg);
}
.c100.p91 .bar {
    transform: rotate(327.6deg);
}
.c100.p92 .bar {
    transform: rotate(331.2deg);
}
.c100.p93 .bar {
    transform: rotate(334.8deg);
}
.c100.p94 .bar {
    transform: rotate(338.4deg);
}
.c100.p95 .bar {
    transform: rotate(342deg);
}
.c100.p96 .bar {
    transform: rotate(345.6deg);
}
.c100.p97 .bar {
    transform: rotate(349.2deg);
}
.c100.p98 .bar {
    transform: rotate(352.8deg);
}
.c100.p99 .bar {
    transform: rotate(356.4deg);
}
.c100.p100 .bar {
    transform: rotate(360deg);
}
.patient-rehabilitation-program-wrapper {
    margin-top: 40px;
}
@media (max-width: 1300px) {
    .patient-rehabilitation-program-wrapper {
        margin-top: 64px;
    }
}
.patient-rehabilitation-program-title {
    font-weight: 800;
    font-size: 26px;
    line-height: 36px;
    color: #000000;
    margin-bottom: 24px;
}
.patient-rehabilitation-program-stages-wrapper {
    background-color: #FFFFFF;
    border: 1px solid #F0F0F0;
    padding: 32px;
    border-radius: 24px;
}
.patient-rehabilitation-program-stage {
    display: flex;
    align-items: center;
    position: relative;
    padding: 16px 0;
}
.patient-rehabilitation-program-stage:first-of-type {
    padding-top: 0;
}
.patient-rehabilitation-program-stage:last-of-type {
    padding-bottom: 0;
}
.patient-rehabilitation-program-stage:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    height: 1px;
    width: 100%;
    background: linear-gradient(90deg, rgba(240, 240, 240, 0) -1.02%, #F0F0F0 50.54%, rgba(240, 240, 240, 0) 100%);
}
.patient-rehabilitation-program-stage:last-of-type:after {
    content: none;
}
.patient-rehabilitation-program-stage-number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 8px;
    background-color: #EFF4F1;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    color: #94B4A4;
}
.patient-rehabilitation-program-stage--over .patient-rehabilitation-program-stage-number {
    background-color: #F0F0F0;
    color: #C9C9C9;
}
.patient-rehabilitation-program-stage--next .patient-rehabilitation-program-stage-number {
    background-color: rgba(33, 76, 79, 0.1);
    color: #214C4F;
}
.patient-rehabilitation-program-stage-status {
    display: flex;
    position: relative;
    padding: 5px 14px 5px 26px;
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    color: #FFFFFF;
    background: radial-gradient(162.96% 359.81% at 14.53% 64.81%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #94B4A4;
    border-radius: 60px;
    margin-right: 12px;
}
.patient-rehabilitation-program-stage--over .patient-rehabilitation-program-stage-status {
    background-color: #C9C9C9;
}
.patient-rehabilitation-program-stage--next .patient-rehabilitation-program-stage-status {
    background: radial-gradient(162.96% 359.81% at 14.53% 64.81%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #214C4F;
}
.patient-rehabilitation-program-stage-status:before {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM6 8C7.10457 8 8 7.10457 8 6C8 4.89543 7.10457 4 6 4C4.89543 4 4 4.89543 4 6C4 7.10457 4.89543 8 6 8Z' fill='white'/%3E%3C/svg%3E%0A");
}
.patient-rehabilitation-program-stage--over .patient-rehabilitation-program-stage-status:before {
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.5 6L5 8.5L10 3.5' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}
.patient-rehabilitation-program-stage--next .patient-rehabilitation-program-stage-status:before {
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.5 6L9.5 6' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6.5 9L9.5 6' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6.5 3L9.5 6' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");}
.patient-rehabilitation-program-stage-load {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #214C4F;
}
.patient-rehabilitation-program-stage--over .patient-rehabilitation-program-stage-load {
    color: #C9C9C9;
}
.patient-rehabilitation-program-stage--next .patient-rehabilitation-program-stage-load {
    color: #214C4F;
}
.patient-rehabilitation-program-stage-load-value {
    display: inline-block;
    font-weight: bold;
    margin-left: 8px;
}
.patient-rehabilitation-program-weeks-wrapper {
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    margin-left: auto;
    padding: 5px 12px;
    border-radius: 16px;
    background-color: #EFF4F1;
    color: #94B4A4;
}
.patient-rehabilitation-program-stage--over .patient-rehabilitation-program-weeks-wrapper {
    background-color: #F0F0F0;
    color: #C9C9C9;
}
.patient-rehabilitation-program-stage--next .patient-rehabilitation-program-weeks-wrapper {
    background-color: rgba(33, 76, 79, 0.1);
    color: #214C4F;
}
.patient-rehabilitation-program-weeks-wrapper span:last-of-type {
    display: inline-block;
    margin-left: 4px;
}

.patient-rehabilitation-details-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 24px;
}
@media (max-width: 1300px) {
    .patient-rehabilitation-details-wrapper {
        flex-wrap: wrap;
    }
}

.patient-rehabilitation-charts-wrapper {
    position: relative;
    padding: 84px 32px 64px;
    background-color: #FFFFFF;
    border-radius: 24px;
    width: 756px;
    flex-shrink: 0;
    overflow: auto;
}
@media (max-width: 1300px) {
    .patient-rehabilitation-charts-wrapper {
        width: 100%;
        flex: 100% 1;
    }
}
.chart-small-items-wrapper {
    display: flex;
    position: relative;
    justify-content: space-between;
    width: 618px;
    margin: 0 auto;
}
.dash {
    position: absolute;
    width: 8px;
    height: 1px;
    background-color: #181827;
    border-radius: 4px;
}
.dash-top-left {
    left: -28px;
}
.dash-top-right {
    right: -28px;
}
.dash-bottom-right {
    right: -28px;
    bottom: 0;
}
.chart-small-note {
    position: absolute;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    color: #181827;
}
.chart-small-note-bottom-left {
    left: -32px;
    bottom: -48px;
}
.chart-small-note-bottom-right {
    right: -32px;
    bottom: -48px;
}
.chart-small-percent {
    position: absolute;
    font-weight: 600;
    font-size: 9px;
    line-height: 12px;
    color: #181827;
    left: -32px;
    bottom: 0;
}
.chart-small-percent.right {
    left: auto;
    right: -32px;
    transform: translateY(50%);
}


.patient-rehabilitation-current-load-wrapper {
    display: flex;
    width: 322px;
    background-color: #FFFFFF;
    border: 1px solid #F0F0F0;
    border-radius: 23px;
    padding: 27px 24px;
}
@media (max-width: 1300px) {
    .patient-rehabilitation-current-load-wrapper {
        width: 100%;
        flex: 100% 1;
        margin-top: 12px;
    }
}
.patient-rehabilitation-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 52px;
    width: 52px;
    height: 52px;
    margin-right: 20px;
    border: 6px solid #EFF4F1;
    border-radius: 50%;
}
.patient-rehabilitation-icon-wrapper.c100 {
    width: 52px;
    height: 52px;
}
.patient-rehabilitation-icon-wrapper.c100 .slice {
    width: 52px;
    height: 52px;
    clip: rect(0, 52px, 52px, 26px);
}
.patient-rehabilitation-icon-wrapper.c100 .fill,
.patient-rehabilitation-icon-wrapper.c100 .bar {
    width: 52px;
    height: 52px;
    clip: rect(0, 26px, 52px, 0);
    border: 6px solid #94B4A4;
}
.patient-rehabilitation-icon-wrapper svg {
    position: relative;
    width: 18px;
    height: 18px;
}
.patient-rehabilitation-icon-wrapper path {
    stroke: #94B4A4;
}
.patient-rehabilitation-current-load {
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    color: #181827;
    margin-bottom: 5px;
}
.patient-rehabilitation-current-load-button {
    display: flex;
    align-items: center;
    padding: 0;
    background-color: transparent;
}
.patient-rehabilitation-current-load-text {
    font-weight: 500;
    font-size: 13px;
    line-height: 13px;
    margin-left: 4px;
    text-align: left;
    /*white-space: nowrap;*/
    color: #9A975A;
    /*border-bottom: 1px solid #9A975A;*/
}
@media (max-width: 1300px) {
    .patient-rehabilitation-current-load-inner-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
}


.patient-chart-section-wrapper {
    margin-top: 40px;
}
.patient-chart-title {
    font-weight: 800;
    font-size: 26px;
    line-height: 36px;
    color: #000000;
    margin-bottom: 24px;
}
.patient-chart-content-wrapper {
    position: relative;
    background-color: #FFFFFF;
    border: 1px solid #F0F0F0;
    border-radius: 24px;
    padding: 24px;
    overflow: auto;
}

.patient-chart-controls-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 57px;
}
.patient-chart-step-control-wrapper {
    display: flex;
    align-items: baseline;
}
.patient-chart-step-control {
    display: flex;
    align-self: center;
    background-color: transparent;
}
.patient-chart-step-control--prev {
    padding-left: 0;
    margin-left: -7px;
}
.patient-chart-step-control-text {
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    color: #9A975A;
    /*margin-left: 32px;*/
}
/*.patient-chart-step-control ~ .patient-chart-step-control-text {
    margin-left: 0;
}*/
.patient-chart-step-control-date-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #999999;
}

.patient-chart-type-controls-wrapper {
    display: flex;
}
.patient-chart-type-control {
    position: relative;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    margin-left: 15px;
    text-align: center;
    color: #999999;
    background-color: transparent;
}
.patient-chart-type-control:hover {
    color: #9A975A;
}
.patient-chart-type-control--active {
    font-weight: 800;
    color: #9A975A;
}
.patient-chart-type-control--active:before {
    content: '';
    position: absolute;
    display: block;
    width: 24px;
    height: 4px;
    top: -24px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #9A975A;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.patient-chart-notice {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 12px 0 30px 45px;
}
.patient-chart-axes-title {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #999999;
}
.y-axes {
    position: absolute;
    top: 70px;
}
.legend-labels-wrapper {
    display: flex;
    justify-content: flex-end;
}
.legend-label {
    position: relative;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #94B4A4;
    margin-right: 32px;
    padding-left: 16px;
}
.legend-label--low {
    color: #214C4F;
}
.legend-label--high {
    color: #C25E6B;
}
.legend-label:last-of-type {
    margin-right: 0;
}
.legend-label:before {
    content: '';
    position: absolute;
    display: block;
    width: 8px;
    height: 8px;
    left: 0;
    bottom: 4px;
    border-radius: 50%;
    background-color: #94B4A4;
}
.legend-label--low:before {
    background-color: #214C4F;
}
.legend-label--high:before {
    background-color: #C25E6B;
}


.patient-chart-doughnut-wrapper {
    display: flex;
    align-items: center;
    background-color: #FFFFFF;
    border: 1px solid #F0F0F0;
    border-radius: 16px;
    padding: 16px;
    width: 289px;
}
.patient-canvas-doughnut-wrapper {
    position: relative;
    margin: -4px 0 -4px -4px;
}
.patient-canvas-doughnut-wrapper svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.patient-canvas-doughnut-wrapper--high path {
    fill: #C25E6B;
}
.patient-canvas-doughnut-wrapper--low path {
    fill: #214C4F;
}
.patient-chart-doughnut-details {
    margin-left: 26px;
}
.patient-chart-doughnut-value {
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    color: #94B4A4;
    margin-bottom: 2px;
}
.patient-chart-doughnut-value--high {
    color: #C25E6B;
}
.patient-chart-doughnut-value--low {
    color: #214C4F;
}
.patient-chart-doughnut-text {
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #94A8AB;
    min-height: 44px;
}
.popup-title.current-load-style {
    margin-top: 0;
}
.current-load {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 137px;
    height: 48px;
    border: 1px solid #F0EFE6;
    border-radius: 12px;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #9A975A;
    margin: 32px auto;
}
.change-load-notice {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #999999;
    margin-bottom: 12px;
}
.current-load-percents-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    color: #CCCBAC;
    margin: 3px 0 30px;
}


.progress-wrapper {
    position: relative;
    height: 32px;
    width: 100%;
    background-image: url("data:image/svg+xml,%3Csvg width='4' height='4' viewBox='0 0 4 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='2' cy='2' r='2' fill='%239A975A'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-size: 24.8px 4px;
    background-repeat: repeat no-repeat;
    overflow: hidden;
}
.progress-wrapper:before {
    content: '';
    position: absolute;
    display: block;
    background-color: #F0EFE6;
    width: 330px;
    height: 6px;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    border-radius: 10px;
    z-index: -1;
}
.progress-button {
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: white;
    top: 50%;
    transform: translateY(-50%);
    border: 10px solid #9A975A;
    padding: 0;
}
.progress-button:before {
    content: '';
    position: absolute;
    height: 6px;
    width: 330px;
    background-color: #9A975A;
    left: -330px;
    top: 50%;
    transform: translateY(-50%);
}
.progress-button:after {
    content: '';
    position: absolute;
    height: 6px;
    width: 330px;
    right: -330px;
    top: 50%;
    transform: translateY(-50%);
}
.patients-header {
    display: flex;
    justify-content: space-between;
    padding: 48px 40px;
    background-color: #FFFFFF;
    box-shadow: 0 6px 24px rgba(233, 235, 233, 0.35);
    border-top-left-radius: 24px;
}
@media (max-width: 1300px) {
    .patients-header {
        padding: 24px;
        border-bottom-left-radius: 24px;
    }
}

.patients-count {
    display: flex;
    align-items: center;
}
.patients-count-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background-color: #FFFFFF;
    box-shadow: 0 6px 24px rgba(233, 235, 233, 0.8);
    border-radius: 50%;
}
.patients-count-data {
    padding-left: 12px;
}
.patients-count-title {
    font-weight: 800;
    font-size: 32px;
    line-height: 36px;
    color: #181827;
    margin-bottom: 2px;
}
.patients-count-info {
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #999999;
}


.header-action-wrapper {
    display: flex;
    align-items: center;
}


.patient-add {
    position: relative;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    padding: 13px 32px;
    border-radius: 12px;
    border: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
}
.header__patient-add {
    margin-left: 16px;
    color: #FFFFFF;
    background-color: #94B4A4;
}
.header__patient-add:hover {
    box-shadow: 0 6px 10px rgba(162, 198, 195, 0.35);
}
.patients-list-empty__patient-add {
    background-color: transparent;
    border: 1px solid #94B4A4;
    color: #94B4A4;
}
.patients-list-empty__patient-add:hover {
    border: none;
    padding: 14px 32px;
    background: #94B4A4;
    color: white;
}
.patient-add svg {
    margin-right: 8px;
}
.patients-list-empty__patient-add path {
    stroke: #94B4A4;
}
.patients-list-empty__patient-add:hover path {
    stroke: white;
}


.patients-list-wrapper {
    margin: 0 40px 40px;
}
.patients-list-item {
    display: flex;
    position: relative;
    background-image: url(/static/media/patients-list-item-background.3aa74e50.svg);
    width: 100%;
    height: auto;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 24px 24px 77px 24px;
    cursor: pointer;
    border-radius: 24px;
    margin-top: -53px;
    -webkit-filter: drop-shadow(1px 0 0 #F0F0F0) drop-shadow(0 1px 0 #F0F0F0) drop-shadow(-1px 0 0 #F0F0F0) drop-shadow(0 -1px 0 #F0F0F0) drop-shadow(0px -6px 24px rgba(233, 235, 233, 0.35));
            filter: drop-shadow(1px 0 0 #F0F0F0) drop-shadow(0 1px 0 #F0F0F0) drop-shadow(-1px 0 0 #F0F0F0) drop-shadow(0 -1px 0 #F0F0F0) drop-shadow(0px -6px 24px rgba(233, 235, 233, 0.35))
}
.patients-list-item:hover {
    -webkit-filter: drop-shadow(1px 0 0 #F0F0F0) drop-shadow(0 1px 0 #F0F0F0) drop-shadow(-1px 0 0 #F0F0F0) drop-shadow(0 -1px 0 #F0F0F0) drop-shadow(0px -6px 24px rgba(233, 235, 233, 0.9));
            filter: drop-shadow(1px 0 0 #F0F0F0) drop-shadow(0 1px 0 #F0F0F0) drop-shadow(-1px 0 0 #F0F0F0) drop-shadow(0 -1px 0 #F0F0F0) drop-shadow(0px -6px 24px rgba(233, 235, 233, 0.9))
}
.patients-list-item:first-of-type {
    margin-top: 0;
}
.patients-list-item:last-of-type {
    padding-bottom: 24px;
}
.patients-list-item-group {
    display: flex;
    width: 50%;
}
.patient-photo-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 0 104px;
    height: 104px;
}
.patient-photo-wrapper img {
    width: 104px;
    height: 104px;
    border-radius: 50%;
}
.patient-status {
    display: inline-block;
    position: absolute;
    bottom: 0;
    padding: 6px 12px;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    text-align: center;
    color: #FFFFFF;
    border-radius: 24px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
}
.patient-status-payment,
.patient-status-start_at {
    background: radial-gradient(162.96% 359.81% at 14.53% 64.81%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #94B4A4;
}
.patient-status-in_progress {
    background: radial-gradient(162.96% 359.81% at 14.53% 64.81%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #9A975A;
}
.patient-status-canceled {
    background: radial-gradient(162.96% 359.81% at 14.53% 64.81%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #C25E6B;
}
.patient-status-completed {
    background: radial-gradient(162.96% 359.81% at 14.53% 64.81%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #214C4F;
}
.patient-data-wrapper {
    padding-left: 24px;
}
.patient-fullname {
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
    color: #181827;
}
.patient-details-wrapper {
    margin: 8px 0;
}
.patient-details-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #999999;
}
.patient-details-text-operation {
    display: inline-block;
    margin-left: 16px;
    margin-right: 3px;
}
.patient-dates {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #181827;
}
.patient-diagnosis {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #181827;
}
.patient-program-container {
    justify-content: space-between;
}
.patient-program-wrapper {
    margin-left: 20%;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #C9C9C9;
}
.patient-program-wrapper--in-progress {
    color: #94B4A4;
}
.patient-program-item {
    display: flex;
    align-items: center;
    margin-bottom: 11px;
}
.patient-program-wrapper--in-progress .patient-program-item--successful {
    color: #94B4A4;
}
.patient-program-wrapper--in-progress .patient-program-item--unsuccessful {
    color: #C25E6B;
}
.patient-program-wrapper--in-progress .patient-program-item--successful path {
    stroke: #94B4A4;
}
.patient-program-wrapper--in-progress .patient-program-item--unsuccessful path {
    stroke: #C25E6B;
}
.patient-program-item:last-of-type {
    margin-bottom: 0;
}
.patient-program-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 26px;
    width: 26px;
    height: 26px;
    margin-right: 12px;
    border: 3px solid #F0F0F0;
    border-radius: 50%;
}
.patient-program-note {
    display: inline-block;
    margin-left: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #C9C9C9;
}
.delete-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
}
.delete-icon-wrapper:hover {
    background-color: rgba(194, 94, 107, 0.05);
}
.delete-icon-wrapper:active {
    background-color: rgba(194, 94, 107, 0.1);
}


.patients-list-empty-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70%;
}
.patients-list-empty-text {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #9A975A;
    margin: 18px 0 32px;
}


.patients-filtered-list-empty-title {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #181827;
    margin-bottom: 12px;
}
.patients-filtered-list-empty-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #181827;
}
.loader-wrapper {
    height: 100%;
}
.loader-progress {
    height: 6px;
    width: 0;
    background: radial-gradient(162.96% 359.81% at 14.53% 64.81%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #9A975A;
    animation: loader-progress-width 4s cubic-bezier(0.4, 0, 1, 1) forwards, loader-progress-width2 infinite, loader-progress-width2 60s 4s ease-out forwards;
}
.loader {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loader svg {
    max-width: 80%;
    animation: loader-opacity 1s infinite alternate cubic-bezier(0.4, 0, 1, 1);
}
@keyframes loader-opacity {
    from {
        opacity: 1;
    }
    to {
        opacity: .3;
    }
}
@keyframes loader-progress-width {
    from {
        width: 0;
    }
    to {
        width: 75%;
    }
}
@keyframes loader-progress-width2 {
    from {
        width: 75%;
    }
    to {
        width: 97%;
    }
}
.support-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 48px 40px;
    background-color: #FFFFFF;
    box-shadow: 0 6px 24px rgba(233, 235, 233, 0.35);
    border-top-left-radius: 24px;
}
@media (max-width: 1300px) {
    .support-header {
        padding: 24px;
        border-bottom-left-radius: 24px;
    }
}

.support-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background-color: #FFFFFF;
    box-shadow: 0 6px 24px rgba(233, 235, 233, 0.8);
    border-radius: 50%;
}
.support-header-title {
    font-weight: 800;
    font-size: 32px;
    line-height: 36px;
    color: #181827;
    margin-left: 12px;
}


.support-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 70%;
    padding: 24px 12px;
    margin-top: 48px;
}
.main-layout .support-content-wrapper {
    margin-top: 0;
}
.support-content-title {
    font-weight: 800;
    font-size: 26px;
    line-height: 36px;
    color: #181827;
    margin: 24px auto 12px;
}
.support-content-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #181827;
}
.links-wrapper {
    margin-top: 48px;
    max-width: 582px;
    width: 100%;
}
.links-separator {
    height: 1px;
    background: linear-gradient(270deg, rgba(240, 240, 240, 0) 0%, #F0F0F0 50.83%, rgba(240, 240, 240, 0) 100%);
    margin: 32px 0 24px;
}
.links-wrapper .group-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}
.link-button-wrapper {
    position: relative;
    width: 229px;
    border-radius: 12px;
    overflow: hidden;
    cursor: pointer;
}
.link-button-wrapper a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 13px 0;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    -webkit-text-decoration-line: none;
            text-decoration-line: none;
}
.link-button-wrapper svg {
    margin-right: 9px;
}
.link-button-gap {
    width: 16px;
}
.telegram-link-wrapper {
    background-color: #5EB5F7;
}
.telegram-link-wrapper:hover {
    box-shadow: 0 6px 10px rgba(150, 183, 208, 0.35);
}
.viber-link-wrapper {
    background-color: #7B519D;
}
.viber-link-wrapper:hover {
    box-shadow: 0 6px 10px rgba(179, 153, 201, 0.35);
}
.whatsapp-link-wrapper {
    background-color: #25E47A;
}
.email-link-wrapper {
    background-color: #94B4A4;
}
.support-link {
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-text-decoration-line: none;
            text-decoration-line: none;
}
.support-link-text {
    position: relative;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    color: #94B4A4;
}
.support-link-text:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background-color: #94B4A4;
}
.phone-icon-wrapper,
.mail-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border: 1px solid #C9D9D1;
    border-radius: 50%;
    margin-right: 12px;
}
.support-link:hover .phone-icon-wrapper,
.support-link:hover .mail-icon-wrapper {
    background-color:  #94B4A4;
    border-color: #94B4A4;
}
.support-link-gap {
    width: 32px;
}
.support-link:hover .phone-icon-wrapper path {
    fill: white;
}
.support-link:hover .mail-icon-wrapper path:first-of-type {
    fill: white;
}
.support-link:hover .mail-icon-wrapper path:last-of-type {
    stroke: #94B4A4;
}
*,
*:before,
*:after {
    box-sizing: border-box;
    outline: none;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}
body {
    margin: 0;
    padding: 0;
    font-family: 'Manrope', sans-serif;
    overflow-x: auto;
    background-color: #F7FBF9;
}
input,
select,
button,
a {
    font-family: 'Manrope', sans-serif;
    border: none;
    outline: none;
}
button {
    cursor: pointer;
}
input {
    background-color: #FFFFFF;
    border: 1px solid #F0F0F0;
    border-radius: 12px;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #94B4A4;
}
input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #999999;
}
input:-ms-input-placeholder, textarea:-ms-input-placeholder {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #999999;
}
input::placeholder,
textarea::placeholder {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #999999;
}
input:hover {
    border-color: #C9C9C9;
}
input:focus {
    border-color: #94B4A4;
}
#root {
    min-height: 100vh;
    background-color: #FAFAFA;
}
.field-wrapper--error input {
    border-color: #C25E6B;
    color: #C25E6B;
}
.button {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    padding: 12px 31px;
    background-color: transparent;
    border: 1px solid transparent;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
}
.button-success {
    background-color: #94B4A4;
    border-color: #94B4A4;
    color: white;
}
.button-success:hover {
    box-shadow: 0 6px 10px rgba(162, 198, 195, 0.35);
}
.button-disabled {
    background-color: #F0F0F0;
    border-color: #F0F0F0;
    color: white;
    pointer-events: none;
}
.button-wrapper {
    width: 100%;
}
.button-wrapper--loading {
    position: relative;
}
.button-wrapper--loading:after {
    content: '';
    position: absolute;
    display: block;
    width: 24px;
    height: 24px;
    top: 50%;
    right: -34px;
    transform: translateY(-50%);
    background-image: url("data:image/svg+xml,%3Csvg class='loader-icon' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' style='display:block;' width='24px' height='24px' viewBox='0 0 100 100' preserveAspectRatio='xMidYMid'%3E%3Ccircle cx='50' cy='50' r='30' stroke-width='10' stroke='%2394b4a4' stroke-dasharray='47.12388980384689 47.12388980384689' fill='none' stroke-linecap='round'%3E%3CanimateTransform attributeName='transform' type='rotate' repeatCount='indefinite' dur='1s' keyTimes='0;1' values='0 50 50;360 50 50'%3E%3C/animateTransform%3E%3C/circle%3E%3C/svg%3E");
}
.button-outline-success {
    border-color: #94B4A4;
    color: #94B4A4;
}
.button-outline-success:hover {
    background-color: #94B4A4;
    color: white;
}
.button-outline-success path {
    stroke: #94B4A4;
}
.button-outline-success:hover path {
    stroke: white;
}
.button-outline-disabled {
    border-color: #F0F0F0;
    color: #F0F0F0;
    pointer-events: none;
}
.button-outline-secondary {
    border-color: #F0F0F0;
    color: #181827;
}

.events-none {
    pointer-events: none;
}
.events-none .tab-input::-webkit-input-placeholder {
    color: #F0F0F0;
}
.events-none .tab-input:-ms-input-placeholder {
    color: #F0F0F0;
}
.tab-label.events-none,
.events-none .tab-label-text,
.events-none .tab-input,
.events-none .tab-input::placeholder {
    color: #F0F0F0;
}
.full-width {
    width: 100%;
}
.loader-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
}
