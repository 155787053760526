.patient-card-wrapper {
    position: relative;
}
.patient-card {
    display: flex;
    background-color: #FFFFFF;
    border: 1px solid #F0F0F0;
    border-radius: 24px;
    padding: 32px;
}

.patient-card-info {
    /*width: 33%;*/
    flex-shrink: 0;
    padding-right: 32px;
}
.patient-card-info-inner-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    max-width: 312px;
    min-width: 280px;
    margin: 0 auto;
}
.patient-card-wrapper--small .patient-photo-wrapper {
    margin-right: 32px;
}
.patient-id {
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    color: #181827;
}
.patient-card-wrapper .patient-id {
    position: absolute;
    top: 0;
    left: 0;
}
.patient-card-wrapper--small .patient-id {
    position: relative;
}
.patient-full-name {
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
}
.patient-card-wrapper--small .patient-full-name {
    margin-top: 8px;
    margin-bottom: 8px;
}
.patient-card-wrapper .patient-full-name {
    margin-top: 16px;
}
.patient-card-separator {
    background: linear-gradient(270deg, rgba(240, 240, 240, 0) 0%, #F0F0F0 50.83%, rgba(240, 240, 240, 0) 100%);
    height: 1px;
    width: 100%;
    margin: 0 auto;
}
.patient-card-more-details .patient-card-separator {
    margin-top: 12px;
    margin-bottom: 9px;
}
.patient-card-more-details .patient-card-ua-date-group ~ .patient-card-separator {
    margin-top: 46px;
    margin-bottom: 46px;
}
.patient-card-wrapper .patient-card-separator {
    max-width: 312px;
    margin-top: 18px;
    margin-bottom: 15px;
}
.patient-card-info-items-wrapper {
    align-self: flex-start;
}
.patient-card-info-item-wrapper {
    display: flex;
    margin-top: 12px;
}
.patient-card-info-img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background-color: #FFFFFF;
    box-shadow: 0 6px 24px rgba(233, 235, 233, 0.7);
    margin-right: 12px;
    border-radius: 50%;
}
.patient-card-info-text {
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    color: #181827;
    margin-top: 6px;
}
.patient-card-info-sub-text {
    display: inline-block;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #999999;
    margin-top: 6px;
    margin-left: 4px;
}

.patient-card-more-details {
    display: flex;
    /*width: 67%;*/
    flex-grow: 1;
    background-color: #F7FBF9;
    border-radius: 16px;
    padding: 32px;
    background-image: url("../../images/patient-card-background.png");
    background-position: center right;
    background-repeat: no-repeat;
    background-size: contain;
}
.patient-card-more-details-inner-wrapper {
    max-width: 314px;
}
.patient-card-more-details-inner-wrapper ~ svg {
    margin: 0 auto;
    transform: translateX(40px);
}
.patient-card-date-group {
    display: flex;
    justify-content: space-between;
}
.patient-card-ua-date-group {
    display: flex;
}
.patient-card-wrapper--small .patient-card-dates-wrapper {
    display: flex;
    align-items: flex-start;
    align-self: center;
    margin-left: auto;
}
.patient-card-wrapper .patient-card-date-wrapper {
    width: 50%;
}
.patient-card-ua-date-group .patient-card-date-wrapper {
    width: auto;
    flex-shrink: 0;
    margin-right: 24px;
}
.patient-card-ua-date-group .patient-card-date-wrapper:last-of-type {
    margin-right: 0;
}
.patient-card-wrapper--small .patient-card-date-wrapper {
    position: relative;
    width: auto;
    padding: 0 16px;
}
.patient-card-wrapper--small .patient-card-date-wrapper:last-of-type {
    padding-right: 0;
}
.patient-card-wrapper--small .patient-card-date-wrapper:after {
    content: '';
    display: block;
    position: absolute;
    width: 1px;
    height: 48px;
    top: 0;
    right: 0;
    background: linear-gradient(0deg, rgba(240, 240, 240, 0) 0%, #F0F0F0 50.83%, rgba(240, 240, 240, 0) 100%);
}
.patient-card-wrapper--small .patient-card-date-wrapper:last-of-type:after {
    content: none;
}
.patient-card-date-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #94B4A4;
    margin-bottom: 8px;
}
.patient-card-date {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #181827;
}
.patient-card-options-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 24px;
}
.patient-card-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 151px;
    height: 61px;
    padding: 16px 20px 16px 18px;
    margin-top: 12px;
    background-color: #FFFFFF;
    border: 1px solid #F0F0F0;
    border-radius: 12px;
}
.patient-card-option-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #999999;
}
.patient-card-option-index {
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    text-align: right;
    color: #181827;
}

.patient-card-expander {
    position: absolute;
    left: 50%;
    bottom: 1px;
    width: 48px;
    height: 24px;
    transform: translate(-50%, 100%);
    background-color: #FFFFFF;
    border-bottom-left-radius: 48px;
    border-bottom-right-radius: 48px;
    border-bottom: 1px solid #F0F0F0;
    border-left: 1px solid #F0F0F0;
    border-right: 1px solid #F0F0F0;
}
.patient-card-expander:after {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 50%;
    transform: translateX(-50%);
    width: 12px;
    height: 8px;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 6.5L6 1.5L11 6.5' stroke='%239A975A' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}
.patient-card-wrapper--small .patient-card-expander:after {
    transform: translateX(-50%) rotate(-180deg);
}
.patient-card-expander-text {
    position: absolute;
    left: 50%;
    bottom: -45px;
    transform: translateX(-50%);
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    color: #9A975A;
}

.patient-card--tablet {
    flex-wrap: wrap;
}
.patient-top {
    flex: 100%;
    width: 100%;
}
.patient-card--tablet .patient-id {
    top: 24px;
    left: 24px;
}
.patient-top .patient-full-name {
    text-align: center;
}
.patient-card--tablet .patient-more-details {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex: 100%;
    width: 100%;
    margin-top: 18px;
}
.patient-card--tablet .patient-more-details:before {
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    flex: 100%;
    background: linear-gradient(270deg, rgba(240, 240, 240, 0) 0%, #F0F0F0 50.83%, rgba(240, 240, 240, 0) 100%);
}
.patient-card--tablet .patient-card-info-items-wrapper {
    position: relative;
    flex: 58% 0 0;
    width: 58%;
}
.patient-card--tablet .patient-card-info-items-wrapper:after {
    content: '';
    display: block;
    position: absolute;
    width: 1px;
    height: 100%;
    right: 0;
    top: 0;
    background: linear-gradient(270deg, rgba(240, 240, 240, 0) -25%, #F0F0F0 100%);
}
.patient-card--tablet .patient-options-wrapper {
    flex: 42% 0 0;
    width: 42%;
    padding: 24px 0 0 24px;
}
.patient-card--tablet .patient-option-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
}
.patient-card--tablet .patient-option-wrapper:last-of-type {
    margin-bottom: 0;
}
.patient-card--tablet .patient-option-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #999999;
}
.patient-card--tablet .patient-option-value {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #181827;
}