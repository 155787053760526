.otp-sms-phone-text {
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    text-align: center;
    color: #181827;
    margin-bottom: 32px;
}
.otp-sms-phone-number {
    font-weight: 700;
}

.otp-inputs {
    display: flex;
    justify-content: center;
}
.otp-inputs .otp-input {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 48px;
    border: 1px solid #C9C9C9;
    border-radius: 12px;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #94B4A4;
    margin-right: 24px;
    text-align: center;
}
.otp-input:last-of-type {
    margin-right: 0;
}
.otp-inputs .otp-input--error {
    border-color: #C25E6B;
    color: #C25E6B;
}

.otp-cta-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #999999;
    margin-top: 12px;
}

.otp-resend-code-text {
    margin-top: 32px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #181827;
}

.otp-timer {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #C9C9C9;
    margin-top: 6px;
}

.otp-resend-code-button {
    display: block;
    margin: 6px auto 0;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #94B4A4;
    background-color: transparent;
}

.otp-next-button-wrapper {
    margin-top: 32px;
    margin-bottom: 12px;
}