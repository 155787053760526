.otp-number-title {
    font-weight: 800;
    font-size: 26px;
    line-height: 36px;
    text-align: center;
    color: #181827;
    margin-top: 24px;
    margin-bottom: 48px;
}

.otp-number-next-button-wrapper {
    margin-top: 48px;
}

.otp-number-consent {
    margin: 12px auto 0;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    text-align: center;
    color: #999;
    max-width: 326px;
}
.otp-number-consent a {
    color: #9a975a;
}

/* @todo: old flow
.otp-number-component .arrow,
.otp-number-component .country-list {
    display: none;
}
.otp-number-component .selected-flag {
    cursor: auto;
    background-image: none !important;
}*/
